import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors } from "../../common/colors";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import profile from "../../assets/images/profile_img.png";
import { faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp as faThumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appServices from "../../services/app-services/appServices";
import OptionsModal from "./OptionsModal";
import { useSelector } from "react-redux";
import { ImageItem } from "../../pages/CreatePost";
import { AsyncImage } from "loadable-image";
import DeleteIcon from "@mui/icons-material/Delete";
import uploadFiles from "../../util/upload-multiple-file";
import Thumbnail from "../Phase2/Thumbnail";
// import { T } from "react-translator-component";
// Dummy data for users

let width = window.innerWidth * 0.9;
let height = window.innerHeight - 400;
// Styled components

const Container = styled.div`
  width: ${width};
  height: ${height};
  margin-top: 7px;
  padding: 12px;
  padding-top: 0;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  border: 2 sol;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ParentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: white;
`;

// Main component

interface Props {
  comments: any[];
  post_id: number;
  closeModal(): void;
}
const options = [
  { name: "ニックネーム", value: "1" },
  { name: "本名", value: "2" },
  { name: "匿名", value: "3" },
];
const CommentsModel = ({ closeModal, comments, post_id }: Props) => {
  // const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const [commentText, setCommentText] = useState<string>("");
  const [editText, setEditText] = useState<string>("");
  const [editCommentId, setEditCommentId] = useState(null);
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [images, setImage] = useState<any>([]);
  const fileInputRef = useRef<any>();
  console.log(comments);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const handleEdit = (id: any) => {
    setEditCommentId(id);
    setReplyCommentId(null);
    setNewComment("");
    // Additional logic to handle editing
  };
  const deleteItem = React.useCallback((itemToDelete: ImageItem) => {
    setImage((currentItems: any) =>
      currentItems.filter((item: any) => item.name !== itemToDelete.name)
    );
  }, []);

  const handleIconClick = () => {
    setError("画像は5枚まで許可されます");
    if (user.client_id === "CL001" || user.client_id === "CL015") {
      if (images.length < 5) {
        handleImageClick();
      } else {
        setError("画像は5枚まで許可されます");
      }
    } else {
      fileInputRef.current.click();
    }
  };

  const handleReply = (id: any) => {
    setReplyCommentId(id);
    // Additional logic to handle replying
  };

  const handleCancelEdit = () => {
    setEditCommentId(null);
    setReplyCommentId(null);
    setNewComment("");
  };

  // const handlePostReply = (parentId: any) => {
  //   // Logic to post the reply
  //   setReplyCommentId(null);
  //   setNewComment("");
  //   setEditCommentId(null);
  // };
  const [loading, setLoading] = useState(false);
  // const [snakeText, setSnakeText] = useState("");
  // const [editCurrentComment, setCurrentEditComment] = useState<any>();

  const [postComments, setPostComments] = useState(comments);
  const [nameDisplayOption, setNameDSP] = useState<any>(undefined);
  const thumbupRegular = faThumbsUpRegular;
  const thumbupSolid = faThumbsUpSolid;

  const likeComment = async (isLiked: boolean, comment_id: any) => {
    if (!isLiked) {
      try {
        // setCommentLike(comment_id);
        await appServices.likeUnlikeComment(comment_id, "like");
        await getSinglePost();
        // setCommentLike("");
      } catch (error) {
        // setCommentLike("");
      }
    } else {
      try {
        // setCommentLike(comment_id);
        await appServices.likeUnlikeComment(comment_id, "delete_like");
        await getSinglePost();
        // setCommentLike("");
      } catch (error) {
        // setCommentLike("");
      }
    }
  };
  const focused = false;
  // const focusCommentArea = (input: any) => {
  //   if (input) {
  //     setTimeout(() => {
  //       input.focus();
  //     }, 500);
  //   }
  // };
  // const onFocus = () => setFocused(true);
  const handleSaveEdit = async (nameDSP: any) => {
    console.log("here edit comment");
    // Logic to save the edited comment
    if (editCommentId)
      await appServices.editComment(editCommentId, editText, post_id, nameDSP);
    try {
      await getSinglePost();
    } catch (error) {}
    setEditCommentId(null);
    setReplyCommentId(null);
    setNewComment("");
  };

  const postComment = async (nameDSP: string) => {
    if (editCommentId) {
      handleSaveEdit(nameDSP);
    } else if (replyCommentId) {
      try {
        setLoading(true);
        const comment = await appServices.replyComment(
          newComment,
          post_id,
          replyCommentId,
          nameDSP
        );
        setLoading(false);
        setCommentText("");
        setNewComment("");
        setReplyCommentId(null);
        // setSnakeText(Yourcommentaddedtext);
        console.log(comment);
        try {
          getSinglePost();
        } catch (error) {}
      } catch (error) {
        setLoading(false);
      }
    } else {
      if (commentText !== "" || images.length > 0) {
        try {
          setLoading(true);
          const allImages = images
            ? await uploadFiles.uploadMultipleImages(images)
            : [];
          const comment = await appServices.createComment(
            commentText,
            post_id,
            null,
            nameDSP,
            allImages
          );
          setLoading(false);
          setCommentText("");
          setImage([]);
          // setSnakeText(Yourcommentaddedtext);
          console.log(comment);
          try {
            getSinglePost();
          } catch (error) {}
        } catch (error) {
          setLoading(false);
        }
      } else {
        console.log("no comment");
      }
    }
  };

  const confirmNameDisplay = () => {
    setNameDSP("1");
  };

  const getSinglePost = async () => {
    const post = await appServices.getSinglePost(post_id);
    console.log(post.post.comments);
    setPostComments(post.post.comments);
  };

  const renderTextWithLinks = (text: string) => {
    // Regular expression to find URLs in the text
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    // Replace URLs with anchor tags
    const replacedText = text.replace(urlRegex, (url) => {
      // Exclude cases like "1.pakistan" or "1.pakistan.com"
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        // Prepend "https://" if the URL doesn't have a protocol specified
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" >${url} </a>`;
      } else {
        return url;
      }
    });

    // Replace newline characters with HTML line breaks
    return replacedText.replace(/\n/g, "<br>");
  };

  const [error, setError] = useState<string | null>("");

  const onImageChange = (event: any) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const totalFiles = images.length + selectedFiles.length;

      if (totalFiles > 5) {
        setError("画像は5枚まで許可されます");
        // Only take the required number of files to stay within the limit
        selectedFiles.splice(5 - images.length);
      } else {
        setError(""); // Clear any previous error
      }

      const newImages = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              imageSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((loadedImages) => {
        setImage((prevImages: any) => [...prevImages, ...loadedImages]);
        // setError(null); // Clear any previous error
      });
    }
  };

  const handleImageClick = () => {
    console.log("button image upload clicked");
    try {
      // const PortalAPP = (window as any).PortalAPP;
      // window.UploadImage();
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.log("image upload", error);
    }
  };

  useEffect(() => {
    function handleMessage(event: any) {
      // Check if the message is coming from the parent window

      console.log("message received");
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (
          event?.data === "" ||
          event?.data === null ||
          event?.data === undefined
        )
          return;
        // alert(event?.data);

        // alert(base64Data);
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setImage((prevImages: any) => [
            ...prevImages,
            {
              name: fileName,
              file: file,
              imageSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          // alert("error" + error);
        }

        // Example: Send a response back to the parent window
        // event.source.postMessage('Hello from iframe!', event.origin);
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  const deletePhoto = (photo: any) => {
    console.log(photo);
  };

  return (
    <Container>
      {/* <LoadingModal
        visible={isLoadingUsers}
        handleClose={() => console.log("")}
      /> */}
      <ParentDiv>
        <ClearButton
          onClick={() => {
            console.log("close");
            closeModal();
          }}
        >
          <Typography
            sx={{ fontSize: 12, color: colors.farm_gray200 }}
            variant="body2"
            color="textPrimary"
          >
            閉じる
          </Typography>
          <KeyboardArrowDownIcon />
        </ClearButton>
      </ParentDiv>
      <div>
        <OptionsModal
          open={nameDisplayOption ? true : false}
          options={options}
          onClose={() => {
            setNameDSP(undefined);
          }}
          onSelect={(option) => {
            setNameDSP(undefined);
            postComment(option);
            console.log(option);
          }}
        />
        <Typography
          style={{ fontSize: 16, fontWeight: "600", textAlign: "center" }}
        >
          コメント
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            color: colors.farm_gray200,
          }}
        >
          相手のことを考え丁寧なコメントを心がけて下さい。不快や攻撃的な言葉づかいなどは掲載を削除させていただくことがあります。
        </Typography>
      </div>
      <div style={{ height: 380, overflow: "auto" }}>
        {postComments.map((item) => (
          <div key={item.id} style={{ marginBottom: 16 }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt=""
                  style={{ height: 30, width: 30 }}
                  src={
                    item.is_anonymous === 3
                      ? profile
                      : item.user_image
                      ? "data:image/jpeg;base64," + item.user_image
                      : profile
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    sx={{ color: "#4792E0", fontSize: 12 }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {
                      item.is_anonymous === 3
                        ? "匿名" // Anonymous
                        : item.is_anonymous === 2 && item.user_name
                        ? item.user_name // Show name if it's provided
                        : item.user_nickname // Otherwise, show the nickname
                    }
                  </Typography>
                }
              />
            </ListItem>
            <div style={{ paddingLeft: 56 }}>
              {editCommentId === item.id ? (
                <TextField
                  fullWidth
                  defaultValue={item.content}
                  onChange={(e) => setEditText(e.target.value)}
                />
              ) : (
                <>
                  {item?.images?.length !== 0 && (
                    <Thumbnail deleteItem={deletePhoto} item={item} />
                  )}
                  <p
                    style={{
                      userSelect: "text",
                      fontSize: 14,
                      fontFamily: "mplus-1c-regular",
                      wordWrap: "break-word",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: renderTextWithLinks(item?.content),
                    }}
                    onClick={(event) => {
                      const target = event.target as HTMLElement;
                      if (target.tagName === "A") {
                        event.preventDefault();
                        const url = (target as HTMLAnchorElement).href;
                        console.log("Link clicked:", url);
                        // You can add additional logic here, such as opening the URL in a new tab/window
                        //window.open(url, "_system");
                        try {
                          const messageData = {
                            url: url,
                            condition: true,
                          };
                          window.parent.postMessage(
                            { type: "url", data: messageData },
                            "*"
                          );
                        } catch (error) {}
                      }
                    }}
                  ></p>
                </>
              )}
              {editCommentId === item.id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 8,
                  }}
                >
                  <Button
                    onClick={() => confirmNameDisplay()}
                    style={{ marginRight: 8 }}
                  >
                    保存
                  </Button>
                  <Button onClick={handleCancelEdit}>キャンセル</Button>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => likeComment(item?.liked_by_me, item.id)}
                  aria-label="thumbs"
                  disabled={user?.id === item?.user_id}
                  size="small"
                >
                  <FontAwesomeIcon
                    icon={item?.liked_by_me ? thumbupSolid : thumbupRegular}
                    color={item?.liked_by_me ? colors.farm_green500 : "gray"}
                  />
                </IconButton>
                <span style={{ fontSize: 14, marginLeft: 4 }}>
                  {item?.likes_count}
                </span>
                {item?.user_id === user.id && (
                  <Button
                    style={{ fontSize: 12, color: colors.farm_gray200 }}
                    onClick={() => handleEdit(item.id)}
                  >
                    修正する
                  </Button>
                )}
                {user.id_kind !== "2" && (
                  <Button
                    style={{ fontSize: 12, color: colors.farm_gray200 }}
                    onClick={() => handleReply(item.id)}
                  >
                    返信する
                  </Button>
                )}
              </div>
            </div>
            {replyCommentId === item.id && (
              <div
                style={{
                  marginLeft: 48,
                  marginTop: 8,
                  width: "calc(100% - 48px)",
                }}
              >
                <TextField
                  fullWidth
                  placeholder="返信を書いてください..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 8,
                  }}
                >
                  <Button
                    onClick={() => confirmNameDisplay()}
                    style={{ marginRight: 8 }}
                  >
                    送信する
                  </Button>
                </div>
              </div>
            )}
            {/* Nested Comments */}
            {item.children &&
              item.children.map((item: any) => (
                <div
                  key={item.id}
                  style={{
                    marginBottom: 16,
                    marginLeft: 30,
                    borderLeftWidth: 2,
                    borderLeftStyle: "solid",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt=""
                        style={{ height: 30, width: 30 }}
                        src={
                          item.is_anonymous === 3
                            ? profile
                            : item.user_image
                            ? "data:image/jpeg;base64," + item.user_image
                            : profile
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ color: "#4792E0", fontSize: 12 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {
                            item.is_anonymous === 3
                              ? "匿名" // Anonymous
                              : item.is_anonymous === 2 && item.user_name
                              ? item.user_name // Show name if it's provided
                              : item.user_nickname // Otherwise, show the nickname
                          }
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div style={{ paddingLeft: 56 }}>
                    {editCommentId === item.id ? (
                      <TextField
                        fullWidth
                        defaultValue={item.content}
                        onChange={(e) => setEditText(e.target.value)}
                      />
                    ) : (
                      <>
                        {item?.images?.length !== 0 && (
                          <Thumbnail deleteItem={deletePhoto} item={item} />
                        )}

                        <p
                          style={{
                            userSelect: "text",
                            fontSize: 14,
                            fontFamily: "mplus-1c-regular",
                            wordWrap: "break-word",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: renderTextWithLinks(item?.content),
                          }}
                          onClick={(event) => {
                            const target = event.target as HTMLElement;
                            if (target.tagName === "A") {
                              event.preventDefault();
                              const url = (target as HTMLAnchorElement).href;
                              console.log("Link clicked:", url);
                              // You can add additional logic here, such as opening the URL in a new tab/window
                              //window.open(url, "_system");
                              try {
                                const messageData = {
                                  url: url,
                                  condition: true,
                                };
                                window.parent.postMessage(
                                  { type: "url", data: messageData },
                                  "*"
                                );
                              } catch (error) {}
                            }
                          }}
                        ></p>
                      </>
                    )}
                    {editCommentId === item.id && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 8,
                        }}
                      >
                        <Button
                          onClick={() => confirmNameDisplay()}
                          style={{ marginRight: 8 }}
                        >
                          保存
                        </Button>
                        <Button onClick={handleCancelEdit}>キャンセル</Button>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => likeComment(item?.liked_by_me, item.id)}
                        aria-label="thumbs"
                        disabled={user?.id === item?.user_id}
                        size="small"
                      >
                        <FontAwesomeIcon
                          icon={
                            item?.liked_by_me ? thumbupSolid : thumbupRegular
                          }
                          color={
                            item?.liked_by_me ? colors.farm_green500 : "gray"
                          }
                        />
                      </IconButton>
                      <span style={{ fontSize: 14, marginLeft: 4 }}>
                        {item?.likes_count}
                      </span>
                      {item?.user_id === user.id && (
                        <Button
                          style={{ fontSize: 12, color: colors.farm_gray200 }}
                          onClick={() => handleEdit(item.id)}
                        >
                          修正する
                        </Button>
                      )}
                      {user.id_kind !== "2" && (
                        <Button
                          style={{ fontSize: 12, color: colors.farm_gray200 }}
                          onClick={() => handleReply(item.id)}
                        >
                          返信する
                        </Button>
                      )}
                    </div>
                  </div>
                  {replyCommentId === item.id && (
                    <div
                      style={{
                        marginLeft: 48,
                        marginTop: 8,
                        width: "calc(100% - 48px)",
                      }}
                    >
                      <TextField
                        fullWidth
                        placeholder="返信を書いてください..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 8,
                        }}
                      >
                        <Button
                          onClick={() => confirmNameDisplay()}
                          style={{ marginRight: 8 }}
                        >
                          送信する
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* Nested Comments */}
                  {item.children &&
                    item.children.map((reply: any) => (
                      <div
                        key={reply.id}
                        style={{ marginLeft: 18, marginTop: 0 }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt=""
                              style={{ height: 30, width: 30 }}
                              src={
                                item.is_anonymous === 3
                                  ? profile
                                  : item.user_image
                                  ? "data:image/jpeg;base64," + item.user_image
                                  : profile
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ color: "#4792E0", fontSize: 12 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {
                                  reply.is_anonymous === 3
                                    ? "匿名" // Anonymous
                                    : reply.is_anonymous === 2 &&
                                      reply.user_name
                                    ? reply.user_name // Show name if it's provided
                                    : reply.user_nickname // Otherwise, show the nickname
                                }
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: 58,
                          }}
                        >
                          {item?.images?.length !== 0 && (
                            <Thumbnail deleteItem={deletePhoto} item={item} />
                          )}
                          <p
                            style={{
                              userSelect: "text",
                              fontSize: 14,
                              fontFamily: "mplus-1c-regular",
                              wordWrap: "break-word",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: renderTextWithLinks(reply?.content),
                            }}
                            onClick={(event) => {
                              const target = event.target as HTMLElement;
                              if (target.tagName === "A") {
                                event.preventDefault();
                                const url = (target as HTMLAnchorElement).href;
                                console.log("Link clicked:", url);
                                // You can add additional logic here, such as opening the URL in a new tab/window
                                //window.open(url, "_system");
                                try {
                                  const messageData = {
                                    url: url,
                                    condition: true,
                                  };
                                  window.parent.postMessage(
                                    { type: "url", data: messageData },
                                    "*"
                                  );
                                } catch (error) {}
                              }
                            }}
                          ></p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                likeComment(reply?.liked_by_me, reply.id)
                              }
                              disabled={user?.id === item?.user_id}
                              aria-label="thumbs"
                              size="small"
                            >
                              <FontAwesomeIcon
                                icon={
                                  reply?.liked_by_me
                                    ? thumbupSolid
                                    : thumbupRegular
                                }
                                color={
                                  reply?.liked_by_me
                                    ? colors.farm_green500
                                    : "gray"
                                }
                              />
                            </IconButton>
                            <span style={{ fontSize: 14, marginLeft: 4 }}>
                              {reply?.likes_count}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div
        style={{
          position: "relative",
          bottom: images.length > 0 ? 60 : 10,
          backgroundColor: colors.farm_gray100,
          width: "100%",
          borderRadius: 4,
        }}
      >
        {images.length > 0 && (
          <div style={{ minHeight: 54 }}>
            <Carousel responsive={responsive}>
              {images.map((item: any) => (
                <div
                  key={item.name}
                  style={{
                    height: 50,
                    width: 150,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: colors.farm_gray100,
                  }}
                >
                  <AsyncImage
                    style={{ height: 50, width: 150, borderRadius: 2 }}
                    src={item.imageSrc}
                    alt=""
                  />
                  <DeleteIcon
                    style={{ top: -30, position: "relative" }}
                    onClick={() => {
                      deleteItem(item);
                    }}
                    color={"warning"}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        )}
        <div
          style={{
            position: "relative",
            minHeight: "65px",
            backgroundColor: "white",
          }}
        >
          {/* Replace minHeight with the appropriate height of your container */}
          {user.id_kind !== "2" && (
            <Stack
              direction={{
                xs: "row",
                sm: "row",
              }}
              justifyContent="flex-end" // This will align items to the bottom
              style={{
                position: "absolute",
                bottom: 5,
                left: 0,
                right: 0,
              }}
            >
              {error && (
                <Snackbar
                  open={error !== ""}
                  autoHideDuration={3000}
                  sx={{ width: "94%" }}
                  onClose={() => setError("")}
                >
                  <Alert
                    onClose={() => setError("")}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {error}
                  </Alert>
                </Snackbar>
              )}
              <IconButton onClick={handleIconClick}>
                <AddPhotoAlternateIcon />
                <input
                  ref={fileInputRef}
                  accept=".png, .jpeg, .jpg"
                  type="file"
                  hidden
                  multiple
                  onChange={onImageChange}
                />
              </IconButton>
              <TextField
                focused={focused}
                fullWidth
                id="filled-multiline-static"
                hiddenLabel
                inputProps={{ maxLength: 200 }}
                multiline
                minRows={1}
                maxRows={2}
                onChange={(ev) => setCommentText(ev.target.value)}
                value={commentText}
                variant="filled"
              />

              {loading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  disabled={commentText.trim() === "" && images.length === 0}
                  onClick={confirmNameDisplay}
                  aria-label="thumbs"
                >
                  <SendIcon />
                </IconButton>
              )}
            </Stack>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CommentsModel;
