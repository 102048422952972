import { Stack } from "@mui/system";
import React, { useState } from "react";
import { styles } from "./styles";
import { colors } from "../common/colors";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
export default function SuggestionBox() {
  const [desc, setDesc] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [snake, setSnake] = useState<any>("");
  const { user } = useSelector((state: any) => state.userAuth);
  const MAX_LENGTH = 250;

  
  const post = async () => {
    if (desc.trim().length > 0) {
      const suggestion = {
        suggestion: {
          title: desc,
          status: "Pending",
        },
      };
      try {
        setLoading(true);
        await appServices.saveSuggestion(suggestion);
        setLoading(false);
        setDesc("");
        setSnake("ご意見をご提出いただきありがとうございます。");
      } catch (error) {
        setLoading(false);
        setSnake("Error!");
      }
    }
  };

  return (
    <div style={{ marginTop: 80 }}>
      <>
        <LoadingModal
          visible={loading}
          handleClose={() => console.log("closed")}
        />
        {user.id_kind !== "2" ? (
          <Stack sx={{ gab: 2, padding: 2 }}>
            <div style={styles.communiTitle}>
              <span style={styles.communiTitleTxt}>意見箱</span>
            </div>
            <p style={{ ...styles.communiTitleDescrip, textAlign: "center" }}>
              ご記入いただいても直ぐには投稿されません。
              ・全ての意見が意見ひろばに投稿される訳ではございません。
            </p>
            <p
              style={{
                ...styles.communiTitleDescrip,
                textAlign: "center",
                color: colors.farm_gray200,
                fontSize: 14,
              }}
            >
              気になること、みんなの意見を聞いてみたいこと、議論してみたいことなどを自由にご記入してください。
              集まった内容をめぶくコミュニティのハンドラーが確認し、意見ひろばにて投稿します。
            </p>
            <span style={{ fontFamily: "mplus-1c-bold" }}>
              本文
              <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>
            </span>
            <TextField
              value={desc}
              sx={{ justifyContent: "start" }}
              hiddenLabel
              multiline
              rows={7}
              inputProps={{ maxLength: MAX_LENGTH }}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
            <span
              style={{
                marginTop: -1,
                fontFamily: "mplus-1c-regular",
                fontSize: 10,
                alignSelf: "end",
                position: "relative",
              }}
            >
              {desc.length}/{MAX_LENGTH}
            </span>
            <Button
              disabled={desc.trim() === ""}
              onClick={() => post()}
              style={{
                alignSelf: "center",
                borderRadius: 20,
                backgroundColor: colors.farm_blue200,
                color: "white",
              }}
              size={"large"}
              variant="contained"
              endIcon={<FontAwesomeIcon icon={faChevronRight} />}
            >
              <span style={{ minWidth: 200 }}>送信する</span>
            </Button>
            <Snackbar
              open={snake !== ""}
              autoHideDuration={3000}
              sx={{ width: "94%" }}
              onClose={() => setSnake("")}
            >
              <Alert
                onClose={() => setSnake("")}
                severity="success"
                sx={{ width: "100%" }}
              >
                {snake}
              </Alert>
            </Snackbar>
          </Stack>
        ) : (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              style={styles.introSliderImg}
              src={require("../assets/sliderimages/community_logo.png")}
              alt=""
            />
            <p>登録ユーザーのみが提案を行うことができます</p>
          </div>
        )}
      </>
    </div>
  );
}
