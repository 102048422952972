import * as React from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import profile from "../assets/images/profile_img.png";
import { LanguageList } from "react-translator-component";
import LogoutIcon from "@mui/icons-material/Logout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { useState } from "react";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { T } from "react-translator-component";
import { colors, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/userAuthReducer";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
export default function MyFarm() {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: any) => state.userAuth);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  // const [community, setCommunityPosts] = useState<any>([]);
  // const [communityPagination, setCommunityPostsPagination] = useState<any>({});
  // const [commentHistory, setCommentHistory] = useState<any>([]);
  // const [commentHistoryPagination, setCommentHistoryPagination] = useState<any>(
  //   {}
  // );
  // const [userImage, setUserImage] = useState<any>();
  // const [userImagee, setUserImagee] = useState<any>();
  React.useEffect(() => {
    if (user?.id) {
      apiCalls();
    }
    if (user.image) {
      // let base64String = user.image;
      // const invalidCharsRegex = /[^A-Za-z0-9+/=]/g;
      // // Replace invalid characters including the '+' sign with an empty string
      // const cleanBase64String = base64String.replace(invalidCharsRegex, "");
      // setUserImagee(cleanBase64String);
      // setUserImage(imageData);
    }
    console.log(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const [expanded, setExpanded] = useState("0");

  const handleChange = (expand: string) => {
    setExpanded(expand);
  };

  const apiCalls = async () => {
    setVisible(true);
    try {
      await appServices.getHistoryPosts(user.id, 1);

      // setCommunityPosts(posts.posts);
      // setCommunityPostsPagination(posts.pagination);
      setVisible(false);
    } catch (error: any) {
      console.log("er", error.response);
      if (error.response.status === 403) {
        logout();
      }
      setVisible(false);
    }
  };

  const handleLangage = () => {
    navigate(0);
  };

  // const handleMoreComment = async () => {
  //   if (commentHistoryPagination?.next_page > 0) {
  //     setVisible(true);
  //     const posts = await appServices.getCommentHistoryPosts(
  //       user.id,
  //       commentHistoryPagination?.next_page
  //     );
  //     setVisible(false);
  //     const newItems = [...commentHistory, ...posts.posts];
  //     setCommentHistory(newItems);
  //     setCommentHistoryPagination(posts.pagination);
  //   }
  // };

  const logout = () => {
    dispatch(reset());
    setAuthorizationToken("");
    navigate("/navigator");
  };
  return (
    <React.Fragment>
      <div style={{ ...styles.farmContainer, padding: 0 }}>
        <LoadingModal
          visible={visible}
          handleClose={() => console.log("here")}
        />
        <LanguageList onChange={handleLangage} Theme="Dropdown" />
        <div style={{ ...styles.farmProfileInfo, flexDirection: "column" }}>
          <img
            src={
              user.image !== null
                ? "data:image/jpeg;base64," + user.image
                : profile
            }
            alt={"userimg"}
            style={styles.farmProfileIcon}
          />

          <span style={styles.farmInfoTitle}>{user.nickname}</span>
          {user?.id_kind === 2 && (
            <span
              style={{
                fontSize: 10,
                color: colors.red[400],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              temp user
            </span>
          )}
          {user?.nav === "1" && (
            <IconButton
              onClick={() => {
                logout();
              }}
              color="inherit"
            >
              Logout &nbsp;
              <LogoutIcon />
            </IconButton>
          )}
        </div>

        <div>
          <Divider style={styles.farmDivider2} />
          {/* <Accordion
            variant="elevation"
            className=""
            style={{ ...styles.farmAccordion }}
            expanded={expanded === "1" ? true : false}
            onChange={() => handleChange(expanded !== "0" ? "0" : "1")}
          >
            <AccordionSummary
              sx={{ color: "black" }}
              expandIcon={
                expanded === "1" ? (
                  <KeyboardArrowUpOutlinedIcon
                    fontSize={"large"}
                    style={{ color: "black" }}
                  />
                ) : (
                  <ArrowForwardIosIcon style={{ color: "black" }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={"black"}>「みんなの投稿」履歴</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {community.length} {T("Pieces")}
              </Typography>
              {
                <>
                  <VerticalList status items={community} />
                  {communityPagination?.next_page > 0 && (
                    <Stack
                      sx={{
                        width: "100%",
                        color: "grey.500",
                        justifyContent: "center",
                      }}
                      spacing={2}
                    >
                      <Button onClick={handleMore} size="small">
                        {T("Load_More")}
                      </Button>
                    </Stack>
                  )}
                </>
              }
            </AccordionDetails>
          </Accordion> */}
          <Divider style={styles.farmDivider2} />

          <div
            style={{
              height: 40,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => navigate("/everyoneshistory")}
          >
            <Typography color={"black"}>「みんなの投稿」履歴</Typography>
            <ArrowForwardIosIcon style={{ marginRight: 6 }} />
          </div>
          <Divider style={{ ...styles.farmDivider, marginTop: 0 }} />
          <div
            style={{
              height: 40,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => navigate("/posthistory")}
          >
            <Typography color={"black"}>プライベート投稿</Typography>
            <ArrowForwardIosIcon style={{ marginRight: 6 }} />
          </div>
          <Divider style={{ ...styles.farmDivider, marginTop: 0 }} />
        </div>
        <div
          style={{
            height: 40,
            padding: 10,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => navigate("/commenthistory")}
        >
          <Typography color={"black"}>{T("Comment_History")}</Typography>
          <ArrowForwardIosIcon style={{ marginRight: 6 }} />
        </div>
        <Divider style={styles.farmDivider2} />

        {/* <div>
          <Accordion
            variant="elevation"
            className=""
            style={{ ...styles.farmAccordion }}
            expanded={expanded === "2" ? true : false}
            onChange={() => handleChange(expanded !== "0" ? "0" : "2")}
          >
            <AccordionSummary
              sx={{ color: "black" }}
              expandIcon={
                expanded === "2" ? (
                  <KeyboardArrowUpOutlinedIcon
                    fontSize={"large"}
                    style={{ color: "black" }}
                  />
                ) : (
                  <ArrowForwardIosIcon style={{ color: "black" }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Comment_History")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {commentHistory.length} {T("Pieces")}
              </Typography>

              <VerticalList items={commentHistory} />
              {commentHistoryPagination?.next_page > 0 && (
                <Stack
                  sx={{
                    width: "100%",
                    color: "grey.500",
                    justifyContent: "center",
                  }}
                  spacing={2}
                >
                  <Button onClick={handleMoreComment} size="small">
                    {T("Load_More")}
                  </Button>
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div> */}

        {/* <div>
          <Accordion style={styles.farmAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Number_of_Likes_Received")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {T("Posts")}: <span>1538</span>
                </span>
                <span>
                  {T("Comments")} <span>285</span>
                </span>
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div> */}

        {/* <div>
          <Accordion
            variant={"elevation"}
            className=""
            style={styles.farmAccordion}
          >
            <AccordionSummary
              style={{ color: "black", alignItems: "center" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color={"black"}>
            
                {T("Reject")}&nbsp;{T("Post")}
              </Typography>
              {rejectPosts.length === 0 ? null : (
                <Badge
                  color="error"
                  style={{ marginLeft: 30, alignSelf: "center" }}
                  badgeContent={rejectPosts.length}
                ></Badge>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"black"}>
                {rejectPosts.length} {T("Pieces")}
                {",  これらの投稿には暴言が含まれる可能性があります。 "}
              </Typography>
              <VotingHorizontalList
                items={rejectPosts}
                title={""}
                onClickItem={(item) =>
                  navigate("/detailsview", { state: item })
                }
                type={"voting"}
                showSeeMore={false}
              />
            </AccordionDetails>
          </Accordion>
          <Divider style={styles.farmDivider2} />
        </div> */}

        <div>
          <Accordion
            variant="elevation"
            className=""
            style={{ ...styles.farmAccordion }}
            expanded={expanded === "3" ? true : false}
            onChange={() => handleChange(expanded !== "0" ? "0" : "3")}
          >
            <AccordionSummary
              sx={{ color: "black" }}
              expandIcon={
                expanded === "3" ? (
                  <KeyboardArrowUpOutlinedIcon
                    fontSize={"large"}
                    style={{ color: "black" }}
                  />
                ) : (
                  <ArrowForwardIosIcon style={{ color: "black" }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{T("Questionnaire")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  onClick={(event) => {
                    const target = event.target as HTMLElement;
                    if (target.tagName === "A") {
                      event.preventDefault();
                      const url = (target as HTMLAnchorElement).href;
                      console.log("Link clicked:", url);
                      // You can add additional logic here, such as opening the URL in a new tab/window
                      //window.open(url, "_system");
                      try {
                        const messageData = {
                          url: url,
                          condition: true,
                        };
                        window.parent.postMessage(
                          { type: "url", data: messageData },
                          "*"
                        );
                      } catch (error) {}
                    }
                  }}
                >
                  <a href="https://forms.office.com/r/7fnxwKXWRW">
                    https://forms.office.com/r/7fnxwKXWRW
                  </a>
                </p>
                <Divider style={styles.farmDivider3} />
                {/* <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Answer1" />
                  <FormControlLabel control={<Checkbox />} label="Answer2" />
                  <FormControlLabel control={<Checkbox />} label="Answer3" />
                  <FormControlLabel control={<Checkbox />} label="Answer4" />
                </FormGroup> */}
              </div>
            </AccordionDetails>
            {/* <AccordionDetails>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{T("Question_title")}</span>
                <Divider style={styles.farmDivider3} />
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Answer1" />
                  <FormControlLabel control={<Checkbox />} label="Answer2" />
                  <FormControlLabel control={<Checkbox />} label="Answer3" />
                  <FormControlLabel control={<Checkbox />} label="Answer4" />
                </FormGroup>
              </div>
            </AccordionDetails> */}
          </Accordion>
          {/* <Divider style={styles.farmDivider4} /> */}
          {/* <Divider style={styles.farmDivider} /> */}
          <Divider style={styles.farmDivider2} />
          <div
            style={{
              height: 40,
              padding: 10,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <Typography color={"black"}>ダッシュボード</Typography>
            <ArrowForwardIosIcon style={{ marginRight: 6 }} />
          </div>

          <Divider style={styles.farmDivider2} />
        </div>
      </div>
    </React.Fragment>
  );
}
